import request from '../request.js'

export default {
    getTemplateList(_data) {
        return request({
            url: '/api/Video/getTemplateList?data=' + JSON.stringify(_data),
            method: 'get',
            headers: {
                Type: 1
            }
        })
    },
}
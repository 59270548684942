import axios from 'axios'
import store from '@/store'
import router from '@/router'
import utils from './utils.js';
import {
    Message
} from 'element-ui'
const service = axios.create({
    baseURL: '/api',
    timeout: 0 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
    config => {

        config.headers['Banben'] = '1';
        config.headers['Device'] = '4';
        config.headers['Deviceid'] = '1';
        config.headers['Timestamp'] = '1';

        let str = config.url;
        // console.log(str.split('/'));

        let token = localStorage.getItem('token');
        // console.log(token,'token')

        config.headers['token'] = token ? token : '';
        console.log(str.split('=')[1], '->>>', str.split('=')[0])
        if (str.split('=')[1]) {
            config.url = str.split('=')[0] + '=' + utils.Encrypt(str.split('=')[1]);
        }

        // console.log(config.url )
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor响应拦截器
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;
        // console.log(response,'111')
        if (res.status == 200) {
            if (res.data) {
                res.data = utils.Decrypt(res.data);
                console.log(res.data, '<-', response.config.url)
                if (res.data || res.data === 0) {
                    return res.data;
                } else {
                    return res;
                }
            } else {
                return res;
            }
        } else if (res.status == 500) {
            return Promise.reject(res)
        } else {
            if (response.headers['content-type'] == "image/png") {
                return res
            } else if (response.request.responseType == 'blob') {
                return res;
            } else {
                if (res.status == 401) {
                    store.commit('setToken', '');
                    localStorage.clear();
                    router.push('/login');
                    Message({
                        message: '登录失效，请重新登录！',
                        type: 'error',
                        duration: 5 * 1000
                    })
                } else {
                    console.log(res)
                }
                return false;
                // Message.error(res.msg);
            }
        }
    },
    error => {
        // console.log(error.message)
        if (error.message == 'Request failed with status code 413') {
            Message({
                message: '文件大于8M，上传失败！',
                type: 'error',
                duration: 5 * 1000
            })
        } else {
            console.log('err---------------')
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }

        return Promise.reject(error)
    }
)

export default service
import request from '../request.js'

export default {
    getVideoList(_data) {
        return request({
            url: '/api/Video/getVideoList?data=' + JSON.stringify(_data),
            method: 'get',
            headers: {
                Type: 2
            }
        })
    },
    getVideo(_data) {
        return request({
            url: '/api/invoice/getVideo?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
    compoundVideo(_data, empno) {
        return request({
            url: '/api/Video/compoundVideo?data=' + JSON.stringify(_data),
            method: 'post',
            data: {empno},
        })
    },
    compoundVideoAsynchronous(_data, empno) {
        return request({
            url: '/api/Video/compoundVideoAsynchronousV2?data=' + JSON.stringify(_data),
            method: 'post',
            data: {empno},
        })
    },
    selectVideo(_data) {
        return request({
            url: '/api/Video/selectVideo?data=' + JSON.stringify(_data),
            method: 'post',
            timeout: 5000
        })
    },
    getParam(_data) {
        return request({
            url: '/api/Video/getParam',
            method: 'post',
            data: _data
        })
    },

    // 动作新增
    downloadVideo(_data) {
        return request({
            url: '/api/Video/downloadVideo?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    clickActivity(_data) {
        return request({
            url: '/api/Video/clickActivity?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
}
import request from '../request.js'

export default {
    afferentOnlyParam(_data) {
        return request({
            url: '/api/Video/afferentOnlyParam?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
    selectOnlyParam(_data) {
        return request({
            url: '/api/Video/selectOnlyParam?data=' + JSON.stringify(_data),
            method: 'post',
        })
    },
}

import request from '../request.js'

export default {
    activityDetail(_data) {
        return request({
            url: '/api/Video/activityDetail?data=' + JSON.stringify(_data),
            method: 'get',
        })
    },
}
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'menu',
    redirect: '/templateList',
    component: () => import('../views/menu.vue'),
  },
  {
    path: '/preView',
    name: 'preView',
    component: () => import('../views/preView.vue')
  },
 
  {
    path: '/templateList',
    name: 'templateList',
    component: () => import('../views/templateList.vue')
  },
  {
    path: '/musicList',
    name: 'musicList',
    component: () => import('../views/musicList.vue'),
    meta: {
      unHide: true,
    }
  },
  {
    path: '/imageAdd',
    name: 'imageAdd',
    component: () => import('../views/imageAdd.vue')
  },
  {
    path: '/imageAdd2',
    name: 'imageAdd2',
    component: () => import('../views/imageAdd2.vue')
  },
  {
    path: '/about2',
    name: 'about2',
    component: () => import('../views/AboutView2.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/VideoView.vue'),
    meta: {
      unHide() {
        if (window.WeixinJSBridge && /Android/.test(navigator.userAgent)) {
          return window.WeixinJSBridge.call('showOptionMenu')
        }
        return window.WeixinJSBridge.call('hideOptionMenu')
      },
    }
  },
  {
    path: '/list',
    name: 'list',

    component: () => import('../views/List.vue'),
    meta: {
      unHide: true,
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/wxSuccess',
    name: 'wxSuccess',
    component: () => import('../views/wxSuccess.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
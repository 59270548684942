import CryptoJS from 'crypto-js/crypto-js';
const KEY = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'retedfgd' 与后台一致
const IV = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'asdqwe' 与后台一致

// 方法
export default {
    // AES加密 ：字符串 key iv  返回base64
    Encrypt:function(str, keyStr, ivStr) {
        let key = KEY;
        let iv = IV;
    
        if (keyStr && ivStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let srcs = CryptoJS.enc.Utf8.parse(str);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            // mode: CryptoJS.mode.ECB,
            mode: CryptoJS.mode.CBC,  // mode 与后台一致
            padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },

    // AES 解密 ：字符串 key iv  返回base64
    Decrypt:function (str, keyStr, ivStr) {
        let key = KEY;
        let iv = IV;

        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        
        if(str){
            let base64 = CryptoJS.enc.Base64.parse(str);
            let src = CryptoJS.enc.Base64.stringify(base64);

            var decrypt = CryptoJS.AES.decrypt(src, key, {
                iv: iv,
                // mode: CryptoJS.mode.ECB,
                mode: CryptoJS.mode.CBC, // 保持一致
                padding: CryptoJS.pad.Pkcs7
            });

            var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        }
        return  JSON.parse(decryptedStr) ;
    },

    splitImgStr: function(str){
        if(str){
            let arr = str.split(',');
            return arr[0]
        }else{
            return str
        }
    },

    // 隐藏手机号中间数目
    hidephone: function(num){
        if(num){
            return num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        }else{
            return num ;
        }
    },
    // 验证手机号
    checkMobile: function (rule, value, callback){
        // console.log(value);
        if (!value) {
            callback(new Error("请输入手机号！"));
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
            callback(new Error("当前输入号码有误，请核对后再次进行输入"));
        } else {
            callback();
        }
    },
    // 验证邮箱
    checkEmail: function (rule, value, callback) {
        // console.log(value);
        const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
        if (!value) {
            callback(new Error("请输入邮箱号！"));
        } else if (!reg.test(value)) {
            callback(new Error("当前输入邮箱号有误，请核对后再次进行输入"));
        } else {
            callback();
        }
    },
    // 校验密码
    checkPasswd: function (rule, value, callback) {
        // 八位包含一个数字一个字母一个符号
        const reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$");
        if (!value) {
            return callback(new Error("请输入密码！"));
        } else if (!reg.test(value)) {
            return callback(new Error("密码至少六位且包含数字字母"));
        } else {
            return callback();
        }
    },

    // 获取本地缓存数据
    getLocal(key,json){
        let val = localStorage.getItem(key);
        if(val && json){
            return JSON.parse(val)
        }else{
            return val
        }
    },

    formateTimeStamp(timeStamp) {
        var day;
        var hour;
        var min;
        var seconds;
      
        day = parseInt(timeStamp / (24 * 60 * 60)) // 计算整数天数
        var afterDay = timeStamp - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
        hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
        var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
        min = parseInt(afterHour / 60) // 计算整数分
        seconds = parseInt(timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数
      
        if (day < 10) {
          day = '0' + day;
        }
      
        if (hour < 10) {
          hour = '0' + hour
        };
      
        if (min < 10) {
          min = '0' + min;
        }
      
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
      
        const restStamp = {
          day: day,
          hour: hour,
          min: min,
          seconds: seconds
        }
        return restStamp
    }
}
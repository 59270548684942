import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isWeiXin: false,
    rec_id: false
  },
  getters: {
    get_isWeiXin(state) {
      return state.isWeiXin
    },
    read_rec_id(state) {
      return state.rec_id
    }
  },
  mutations: {
    set_isWeixin(state, payload) {
      // 如果有随机id，则不生成，避免用户返回再次点击
      
      state.isWeiXin = payload
    },
    gent_rec_id(state) {
      if (state.rec_id) {
        return
      }
      
      state.rec_id = Date.now() + parseInt(Math.random() * 0xffff).toString(16)
    }
  },
  actions: {
  },
  modules: {
  }
})

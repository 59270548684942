import Vue, { onMounted } from 'vue'

import api from './assets/js/api'
import ElementUI from 'element-ui';
import utils from './assets/js/utils.js'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/lib.scss';
import '@/assets/scss/style.scss';

Vue.use(ElementUI);

import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/rem.js';

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.utils = utils;
Vue.config.ignoredElements = ['wx-open-launch-weapp']

document.addEventListener('WeixinJSBridgeReady', function () {
  store.commit('set_isWeixin', Boolean(window.WeixinJSBridge))
  window.WeixinJSBridge.call('hideOptionMenu')
  Vue.mixin({
    beforeRouteEnter(to, from, next) {
      
      next(() => {
        if (to?.meta?.unHide instanceof Function) {
          return to.meta.unHide()
        }
        if (!to?.meta?.unHide) {
          window.WeixinJSBridge.call('hideOptionMenu')
        } else {
          window.WeixinJSBridge.call('showOptionMenu')
        }
      })
    }
  })
  
});
 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')